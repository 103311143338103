import { useCallback } from 'react'

import { Theme } from '@mui/material/styles'
import { Box, Checkbox, FormControlLabel, Typography, styled } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTextField from 'src/@core/components/mui/text-field'

import { WithFormType } from 'src/types/forms/utils'

type CheckboxWithTextFieldProps = WithFormType & {
  title?: string
  description?: string
  formItems: {
    checkboxName: string
    inputName: string
    inputLabel: string
  }
}

const Container = styled(Box)(({ theme, isChecked }: { theme: Theme; isChecked: boolean }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  border: '1px solid',
  borderColor: isChecked ? theme.palette.primary.main : theme.palette.grey[200]
}))

const StyledCheckbox = styled(Checkbox)({
  padding: 0
})

const StyledFormControlLabel = styled(FormControlLabel)({
  padding: 0,
  margin: 0
})

const StyledTextField = styled(CustomTextField)({
  flex: 1
})

export const CheckboxWithTextField = ({
  title,
  description,
  form: { control, formState, watch, setValue },
  formItems: { checkboxName, inputName, inputLabel }
}: CheckboxWithTextFieldProps) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const isCheckboxChecked = watch(checkboxName)

  const handleCheckContainer = useCallback(
    () => setValue(checkboxName, !isCheckboxChecked),
    [isCheckboxChecked, checkboxName, setValue]
  )
  const handleStopPropagation = useCallback((e: React.MouseEvent<HTMLElement>) => e.stopPropagation(), [])

  return (
    <Container theme={theme} isChecked={isCheckboxChecked} onClick={handleCheckContainer}>
      <Box alignSelf={'flex-start'}>
        <Controller
          name={checkboxName}
          control={control}
          render={({ field: { value, onChange } }) => (
            <StyledFormControlLabel
              onChange={onChange}
              checked={value}
              control={<StyledCheckbox disableRipple checked={value} />}
              label=''
            />
          )}
        />
      </Box>

      <Box display='flex' flexDirection='column' gap={theme.spacing(2)}>
        {title && <Typography variant='h6'>{title}</Typography>}

        {description && <Typography variant='body2'>{description}</Typography>}

        <Box paddingBottom={theme.spacing(3.7)}>
          <Controller
            name={inputName}
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <StyledTextField
                label={inputLabel}
                fullWidth
                value={value}
                onChange={onChange}
                disabled={!isCheckboxChecked}
                error={Boolean(formState.errors[inputName])}
                helperText={formState.errors[inputName] && t(`${formState.errors[inputName]?.message}`)}
                onClick={handleStopPropagation}
              />
            )}
          />
        </Box>
      </Box>
    </Container>
  )
}
