import { Box, Typography } from '@mui/material'
import { alpha, useTheme } from '@mui/system'

type GDPRContractGuideStepProps = {
  stepNumber: number
  info: string
  additionalInfo?: string | JSX.Element | React.ReactNode
}

export const GDPRContractGuideStep = ({ stepNumber, info, additionalInfo }: GDPRContractGuideStepProps) => {
  const theme = useTheme()

  return (
    <Box display='flex' flexDirection='row' gap={theme.spacing(4)}>
      <Box>
        <Box
          bgcolor={alpha(theme.palette.primary.main, 0.16)}
          borderRadius={theme.spacing(1)}
          paddingX={theme.spacing(2.5)}
          paddingY={theme.spacing(1.5)}
        >
          <Typography sx={{ color: theme.palette.primary.main }} variant='subtitle2'>
            {stepNumber}.
          </Typography>
        </Box>
      </Box>

      <Box display='flex' flexDirection='column' justifyContent={'center'} gap={additionalInfo ? theme.spacing(3) : 0}>
        <Typography variant='body2'>{info}</Typography>

        {additionalInfo && <Box>{additionalInfo}</Box>}
      </Box>
    </Box>
  )
}
