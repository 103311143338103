import { useCallback, useMemo, useState } from 'react'

import { Box, Button, Checkbox, FormControlLabel, Stack, styled, Typography, useTheme } from '@mui/material'
import { Theme } from '@mui/system'

import DOMPurify from 'dompurify'
import { useTranslation } from 'react-i18next'

import { GDPR_ACCEPTATION_KEY } from 'src/utils/settings/portal-settings/constants'
import { cleanText } from 'src/utils/html'

import { LargeBannerType, LargeBannerTypeId } from 'src/types/settings'

type GDPRBannerProps = {
  showSmallBanner?: boolean | null
  showLargeBanner?: boolean | null
  smallBannerContent: {
    bannerText?: string | null
    acceptButtonLabel?: string | null
    rejectButtonLabel?: string | null
  }
  largeBannerContent: {
    largeBannerType?: LargeBannerTypeId
    cookieText?: string | null
    detailsText?: string | null
    aboutText?: string | null
  }
}

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    color: theme.palette.text.secondary
  }
}))

const SmallBannerWrapper = styled(Stack)(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  zIndex: 2,
  bottom: 0,
  backgroundColor: theme.palette.customColors.cardBg,
  padding: theme.spacing(6, 8, 6, 8),
  boxShadow: `0 5px 20px 0 ${theme.colorWithOpacity('#4B465C', 40)}`,
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap'
  }
}))

const LargeBannerWrapper = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isBottomBanner'
})<{ isBottomBanner: boolean }>(({ theme, isBottomBanner }) => ({
  maxWidth: 562,
  position: 'absolute',
  zIndex: 2,
  backgroundColor: theme.palette.customColors.cardBg,
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(6, 8, 6, 8),
  boxShadow: `0 5px 20px 0 ${theme.colorWithOpacity('#4B465C', 40)}`,
  ...(isBottomBanner
    ? {
        bottom: 26,
        left: 26,
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          left: 0,
          right: 0
        }
      }
    : {
        top: '50%',
        left: '50%',
        width: '100%',
        transform: 'translate(-50%, -50%)',
        [theme.breakpoints.down('sm')]: {
          width: 'calc(100% - 32px)'
        }
      })
}))

const TabHeader = styled(Stack)(({ theme }) => ({
  width: '100%',
  borderBottom: `1px solid ${theme.palette.divider}`
}))

const TabElement = styled(Typography)(({ theme, isSelected }: { theme: Theme; isSelected: boolean }) => ({
  cursor: 'pointer',
  position: 'relative',
  color: isSelected ? theme.palette.primary.main : theme.palette.text.secondary,
  padding: theme.spacing(2, 4.5, 2, 4.5),
  transition: 'color 0.3s ease',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: -1,
    left: 0,
    right: 0,
    height: 2,
    backgroundColor: theme.palette.primary.main,
    transform: isSelected ? 'scaleX(1)' : 'scaleX(0)',
    transition: 'transform 0.3s ease'
  }
}))

const TabContent = styled(Typography)({
  width: '100%',
  height: 110,
  overflowX: 'auto',
  position: 'relative'
})

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}))

const StyledButton = styled(Button)({
  alignSelf: 'center'
})

function isEmptyParagraph(html: string): boolean {
  return /^(\s*<p>\s*<\/p>\s*)*$/i.test(html)
}

export const GDPRBanner = ({
  showSmallBanner,
  showLargeBanner,
  smallBannerContent: { bannerText, acceptButtonLabel, rejectButtonLabel },
  largeBannerContent: { largeBannerType, cookieText, detailsText, aboutText }
}: GDPRBannerProps) => {
  const theme = useTheme()
  const { t } = useTranslation('translation', { keyPrefix: 'PAGE.ANALYTICS_GDPR_SETTINGS' })
  const { t: tGlobal } = useTranslation()
  const [isGDPRAccepted, setIsGDPRAccepted] = useState(!!window?.localStorage.getItem(GDPR_ACCEPTATION_KEY))

  const [largeBannerCheckbox, setLargeBannerCheckbox] = useState({
    necessary: true,
    statistics: false,
    marketing: false
  })

  const [sanitizedBannerText, sanitizeCookieText, sanitizeDetailsText, sanitizeAboutText] = useMemo(
    () => [
      DOMPurify.sanitize(cleanText(bannerText || '') || ''),
      DOMPurify.sanitize(cleanText(cookieText || '') || ''),
      DOMPurify.sanitize(cleanText(detailsText || '') || ''),
      DOMPurify.sanitize(cleanText(aboutText || '') || '')
    ],
    [bannerText, cookieText, detailsText, aboutText]
  )

  const tabs = {
    Cookies: sanitizeCookieText,
    Details: sanitizeDetailsText,
    'About cookies': sanitizeAboutText
  }

  const [selectedTab, setSelectedTab] = useState<keyof typeof tabs>('Cookies')

  const onChangeLargeBannerCheckbox = useCallback(
    (field: keyof typeof largeBannerCheckbox) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setLargeBannerCheckbox(prevState => ({
        ...prevState,
        [field]: event.target.checked
      }))
    },
    []
  )

  const handleClickSmallBanner = useCallback(
    (type: 'accept' | 'reject') => () => {
      const isAccepted = type === 'accept'

      window.localStorage.setItem(
        GDPR_ACCEPTATION_KEY,
        JSON.stringify({
          accepted_at_epoch: new Date().getTime(),
          is_accept: isAccepted,
          is_marketing: isAccepted,
          is_statistics: isAccepted
        })
      )

      setIsGDPRAccepted(true)
    },
    []
  )

  const handleLargeBannerAction = useCallback(
    (type: 'deny all' | 'allow selection' | 'accept all') => () => {
      const isAccept = type === 'accept all'
      const params = {
        accepted_at_epoch: new Date().getTime(),
        is_accept: isAccept || type === 'allow selection',
        is_statistics: isAccept || (type === 'allow selection' ? largeBannerCheckbox.statistics : false),
        is_marketing: isAccept || (type === 'allow selection' ? largeBannerCheckbox.marketing : false)
      }
      window.localStorage.setItem(GDPR_ACCEPTATION_KEY, JSON.stringify(params))

      setIsGDPRAccepted(true)
    },
    [largeBannerCheckbox]
  )

  const handleTabClick = useCallback((tab: keyof typeof tabs) => () => setSelectedTab(tab), [])

  if (isGDPRAccepted) {
    return null
  }

  if (showSmallBanner) {
    return (
      <SmallBannerWrapper gap={theme.spacing(6)} direction='row'>
        <Stack
          justifyContent={'center'}
          dangerouslySetInnerHTML={{
            __html: isEmptyParagraph(sanitizedBannerText) ? tGlobal('COOKIE_BANNER.agree') : sanitizedBannerText
          }}
        />

        <ButtonContainer display='flex' gap={theme.spacing(4.5)} justifyContent='flex-end'>
          <StyledButton variant='text' onClick={handleClickSmallBanner('reject')}>
            {rejectButtonLabel || tGlobal('ACTION.reject')}
          </StyledButton>

          <StyledButton variant='contained' onClick={handleClickSmallBanner('accept')}>
            {acceptButtonLabel || tGlobal('ACTION.accept')}
          </StyledButton>
        </ButtonContainer>
      </SmallBannerWrapper>
    )
  }

  if (showLargeBanner) {
    return (
      <LargeBannerWrapper gap={theme.spacing(6)} isBottomBanner={largeBannerType === LargeBannerType.BOTTOM_BANNER}>
        <Stack gap={theme.spacing(4)}>
          <TabHeader direction='row'>
            {Object.keys(tabs).map(key => (
              <TabElement
                variant='body1'
                theme={theme}
                isSelected={selectedTab === key}
                key={key}
                onClick={handleTabClick(key as keyof typeof tabs)}
              >
                {key}
              </TabElement>
            ))}
          </TabHeader>

          <TabContent variant='body1' dangerouslySetInnerHTML={{ __html: tabs[selectedTab] }} />

          <Stack direction='row' flexWrap='wrap'>
            <StyledFormControlLabel
              label={t('necessary_label')}
              control={
                <Checkbox
                  disabled
                  checked={largeBannerCheckbox.necessary}
                  onChange={onChangeLargeBannerCheckbox('necessary')}
                />
              }
            />

            <StyledFormControlLabel
              label={t('statistics_label')}
              control={
                <Checkbox
                  checked={largeBannerCheckbox.statistics}
                  onChange={onChangeLargeBannerCheckbox('statistics')}
                />
              }
            />

            <StyledFormControlLabel
              label={t('marketing_label')}
              control={
                <Checkbox checked={largeBannerCheckbox.marketing} onChange={onChangeLargeBannerCheckbox('marketing')} />
              }
            />
          </Stack>
        </Stack>

        <Stack direction='row' flexWrap='wrap' gap={theme.spacing(4)} alignSelf='flex-end'>
          <StyledButton variant='text' onClick={handleLargeBannerAction('deny all')}>
            {t('BUTTON.deny_all')}
          </StyledButton>

          <StyledButton variant='text' onClick={handleLargeBannerAction('allow selection')}>
            {t('BUTTON.allow_selection')}
          </StyledButton>

          <StyledButton variant='contained' onClick={handleLargeBannerAction('accept all')}>
            {t('BUTTON.allow_all')}
          </StyledButton>
        </Stack>
      </LargeBannerWrapper>
    )
  }

  return null
}
